

























import { computed, defineComponent, reactive, ref, toRefs } from '@vue/composition-api';
import useTeamDoc from '@/composables/useTeamDoc';
import { ActionTypes } from '@/store/modules/tools/actions';
import store, { useUserGetters } from '@/store';

export default defineComponent({
  name: 'Team',
  components: {
    MTeamAdkTable: () => import('./m-team-adk-table.vue'),
    MJoinTeam: () => import('./m-join-team.vue')
  },
  props: {
    programDoc: {
      required: false,
      type: Object,
      default: () => {}
    },
    value: {
      type: Object,
      default: () => {}
    },
    userType: {
      type: String,
      default: ''
    },
    updateAdk: {
      type: Function,
      default: () => {}
    },
    studentId: {
      type: String,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any, ctx: any) {
    const state = reactive({
      page: 1,
      joinTeamDialog: false,
      password: '',
      error: ''
    });
    const maxTeamMembers = ref(3);
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const teamDoc: any = ref();
    const teamList = ref([]);
    const isLoading = ref(false);
    const { getTeamDoc, getTeamList }: any = useTeamDoc();
    async function loadInitialData() {
      // store.dispatch(`tools/${ActionTypes.showLoader}`);
      isLoading.value = true;
      teamDoc.value = await getTeamDoc({
        program_id: ctx.root.$route.params.programId || props.value?.data?.program_id,
        user_id: props.isMonitor ? props.studentId : user?.value?._id.toString(),
        public_id: props.value?.data?._id.toString()
      });
      if (teamDoc.value.status === 400) {
        teamList.value = await getTeamList({
          program_id: ctx.root.$route.params.programId || props.value?.data?.program_id,
          public_id: props?.value?.data?._id
        });
        ctx.emit('team-doc', teamDoc.value);
        // store.dispatch(`tools/${ActionTypes.showLoader}`);
        isLoading.value = false;
      } else {
        ctx.emit('team-doc', teamDoc.value);
        isLoading.value = false;
        // store.dispatch(`tools/${ActionTypes.showLoader}`);
      }
    }

    loadInitialData();

    function updateTeamData(val) {
      teamDoc.value = { ...val };
      ctx.emit('team-doc', teamDoc);
    }

    return {
      ...toRefs(state),
      updateTeamData,
      teamDoc,
      isLoading,
      teamList,
      maxTeamMembers,
      user
    };
  }
});
